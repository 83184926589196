<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.contact_support') }}</h1>
      </header>

      <div v-if="successMessage">
        <p>
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>

        <button type="submit" class="btn btn-primary mt-4" @click="resetFields()">
          <i class="fas fa-edit"></i>{{ $t('message.new_request') }}
        </button>
      </div>

      <div class="contact-fiducial mb-5" v-if="successMessage === null">
        <div>
          <span><strong>{{ $t('message.are_you_employee') }}</strong></span>
        </div>

        <div class="contact-actions mb-2">
          <button type="submit" class="btn btn-primary mt-4 mr-2" @click="isFiducial = true">
            <i class="fas fa-check-circle"></i>{{ $t('message.yes') }}
          </button>
          <button type="submit" class="btn btn-primary mt-4" @click="isFiducial = false">
            <i class="fas fa-check-circle"></i>{{ $t('message.no') }}
          </button>
        </div>

        <div class="extras">
          {{ $t('message.access_issue') }} <span class="click-anyway" @click="isFiducial = false">{{ $t('message.click_here') }}</span>
        </div>
      </div>

      <div v-if="isFiducial === true">
        <p>
          <v-alert
            outlined
            type="info"
            border="left"
          >
            <strong>
              <router-link to="/mot-de-passe-oublie" class="recovery">{{ $t('message.click_here_login_issue') }}</router-link>
            </strong>
          </v-alert>
          <v-alert
            outlined
            type="warning"
            border="left"
          >
            <strong>
              {{ $t('message.other_problems') }}
              <br>
              {{ $t('message.no_access_intranet') }}
            </strong>
          </v-alert>
        </p>
      </div>

      <div id="contact_form" v-if="isFiducial === false && successMessage === null">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <p>
            <v-alert
              outlined
              type="warning"
              border="left"
            >
              <strong>{{ $t('message.workflow_reminder') }}</strong>
            </v-alert>
          </p>

          <p>
            <v-alert
              outlined
              type="warning"
              border="left"
            >
              <strong>{{ $t('message.spam_reminder') }}</strong>
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ $t(error.title) }}
            </v-alert>
          </p>

          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            :label="$t('message.last_name') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            :label="$t('message.first_name') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="employeeId"
            :label="$t('message.employee_id')"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="$t('message.email') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            :label="$t('message.phone') + '*'"
            required
          ></v-text-field>

          <v-select
            v-model="category"
            :items="categories.map(category => $t(category))"
            :rules="[v => !!v || $t('message.category_required')]"
            :label="$t('message.category') + '*'"
            required
          ></v-select>

          <v-textarea
            v-model="message"
            :rules="messageRules"
            name="input-7-1"
            :label="$t('message.message') + '*'"
          ></v-textarea>

          <br>

          <p>
            {{ $t('message.agreement') }} <router-link to="/politique-de-confidentialite">{{ $t('message.privacy_policy') }}</router-link>
          </p>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>{{ $t('message.send') }}
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'Contact',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    isFiducial: null,
    lastName: null,
    firstName: null,
    employeeId: null,
    email: null,
    phone: null,
    category: null,
    categories: [
      'message.category_account_creation',
      'message.category_login',
      'message.category_document_reception',
      'message.category_account_management',
      'message.category_personal_data_management',
      'message.category_missing_pay_slip',
      'message.category_error_message',
      'message.category_other_issues'
    ],
    message: null
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)

    const user = this.$store.getters.getUser
    const currentCompany = this.$store.getters.getCurrentCompany
    const companies = this.$store.getters.getCompanies

    if (user) {
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.email = user.email

      if (currentCompany && companies) {
        const company = companies.find(element => element.company.id === currentCompany.id)

        this.employeeId = company.employeeId
      }
    }

    document.title = config.title + ' - ' + this.$t('message.contact_support')
  },
  computed: {
    currentCompany () {
      return this.$store.getters.getCurrentCompany
    },
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    },
    phoneRules () {
      return [
        v => !!v || this.$t('message.mandatory_phone')
      ]
    },
    messageRules () {
      return [
        v => !!v || this.$t('message.mandatory_message')
      ]
    },
    lastNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_last_name')
      ]
    },
    firstNameRules () {
      return [
        v => !!v || this.$t('message.mandatory_first_name')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.postContact()
      }
    },
    postContact () {
      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        employeeId: this.employeeId,
        email: this.email.toLowerCase(),
        phone: this.phone,
        category: this.category,
        message: this.message
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      const options = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken,
          'Content-Type': 'multipart/form-data'
        }
      }

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/contacts', formData, options)
        .then(response => {
          if (response.status === 200) {
            this.$router.push({ name: 'ContactValidation' })
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.loading = false
        })
    },
    resetFields () {
      this.category = null
      this.message = null
      this.successMessage = null
    }
  }
}
</script>
